import React from 'react';
import { SingleBlogGenre } from 'views/single-blog-genre';
import { useBlogHr } from 'views/single-blog-genre/hooks/use-blog-hr';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/blog.json';
import hrMessages from 'constants/translations/blog/hr.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const BlogHrPage = ({ location, data }) => {
    const blogPosts = data.allContentfulBlogPost.edges;
    const faqData = data.allContentfulFaq.nodes[0];
    const pageData = useBlogHr(blogPosts, faqData);

    return (
        <Layout location={location} messages={{ ...messages, ...hrMessages }}>
            <SingleBlogGenre {...pageData} />
        </Layout>
    );
};

export default BlogHrPage;

BlogHrPage.propTypes = {
    location: object.isRequired,
    data: object.isRequired,
};

export const Head = () => <SEO tags={TAGS.BLOG_HR} />;

export const pageQuery = graphql`
    query EnHrBlogPageQuery {
        allContentfulBlogPost(
            filter: {
                node_locale: { eq: "en-US" }
                metadata: {
                    tags: {
                        elemMatch: {
                            name: { in: "eb-hr-resources", ne: "nft-reality" }
                        }
                    }
                }
            }
            sort: { date: DESC }
        ) {
            edges {
                node {
                    ...BlogPostFragment
                }
            }
        }
        allContentfulFaq(filter: { slug: { eq: "blog/hr" } }) {
            nodes {
                ...FaqFragment
            }
        }
    }
`;
